<script lang="ts" setup>
    import {
        AdresseCell,
        EmailCell,
        GenderCell,
        LandesstelleFilter,
        type LandesstelleFilterEvent,
        QualifikationCell,
        SchulungCell,
        TelefonnummerCell
    } from '@/components'
    import { type Betriebsbeauftragter } from '@/js/model'
    import { useBetriebsbeauftragterService } from '@/services'
    import { useAuthStore, useFachprueferStore, useLandesstellenStore } from '@/stores'
    import {
        type FetchParams,
        formatDate,
        itemsPerPageOptions,
        itemsPerPageText,
        loadingText,
        noItemsText,
        type SortItem,
        type TableHeaders
    } from '@/utils'
    import { onBeforeMount, ref, type Ref, watch } from 'vue'

    const authStore = useAuthStore()

    const itemsPerPage = ref<number>(25)
    const allHeaders: TableHeaders = [
        { title: 'ID in Access', align: 'start', key: 'betriebsbeauftragterAccessId' },
        { title: 'Landesstelle', align: 'start', key: 'landesstelleId' },
        { title: 'Fachbetrieb', align: 'start', key: 'fachbetriebsId' },
        { title: 'Fachprüfer', align: 'start', key: 'fachprueferId' },
        { title: 'Vorname', align: 'start', key: 'vorname' },
        { title: 'Nachname', align: 'start', key: 'nachname' },
        { title: 'Geschlecht', align: 'start', key: 'gender', sortable: false },
        { title: 'Firma', align: 'start', key: 'firma', sortable: false },
        { title: 'Telefon', align: 'start', key: 'kontakt', sortable: false },
        { title: 'E-Mail', align: 'start', key: 'kontakt.email', sortable: false },
        { title: 'Adresse', align: 'start', key: 'firmenAdresse', sortable: false },
        { title: 'Schulungen', align: 'start', key: 'schulungen.schulungen', sortable: false },
        { title: 'Qualifikation', align: 'start', key: 'qualifikation', sortable: false },
        { title: 'Fachrichtung', align: 'start', key: 'fachrichtung' },
        {
            title: 'Geburtsdatum',
            align: 'start',
            key: 'geborenAt',
            value: (item) => `${item.geborenAt ? formatDate(item.geborenAt) : '-'}`
        },
        { title: 'Geburtsort', align: 'start', key: 'geborenIn' },
        { title: 'Alte Daten', align: 'start', key: 'alteDaten', sortable: false },
        {
            title: 'Abschlussdatum',
            align: 'start',
            key: 'abschlussAt',
            value: (item) => `${item.abschlussAt ? formatDate(item.abschlussAt) : '-'}`
        },
        {
            title: 'Letzte Änderung',
            align: 'start',
            key: 'updatedAt',
            value: (item) => `${formatDate(item.updatedAt)}`
        }
    ]

    const headers = ref<TableHeaders>(
        authStore.hasRole('Fachpruefer')
            ? allHeaders
            : allHeaders.filter(
                  (header) =>
                      undefined !== header.key && !['kontakt', 'kontakt.email'].includes(header.key)
              )
    )

    const serverItems: Ref<Betriebsbeauftragter[]> = ref([])
    const tableOptions = ref<{ sortBy: SortItem[]; page?: number; itemsPerPage?: number }>({
        sortBy: []
    })
    const loading = ref<boolean>(true)
    const vorname = ref<string | null>(null)
    const nachname = ref<string | null>(null)
    const firma = ref<string | null>(null)
    const search = ref<string>('')
    const totalItems = ref<number>(0)
    const landesstelleFilter = ref<LandesstelleFilterEvent>({ all: true, selection: [] })

    const betriebsbeauftragterService = useBetriebsbeauftragterService()
    const landesstellenStore = useLandesstellenStore()
    const fachprueferStore = useFachprueferStore()

    const loadItems = async ({
        page,
        itemsPerPage,
        sortBy
    }: FetchParams & {
        sortBy: SortItem[]
    }) => {
        loading.value = true
        tableOptions.value = {
            page,
            itemsPerPage,
            sortBy
        }
        let data: FetchParams = {
            page,
            itemsPerPage,
            vorname: vorname.value,
            nachname: nachname.value,
            'firma.name': firma.value,
            ...getLandesstellenFilter()
        }

        if (sortBy[0]) {
            sortBy.forEach((val: SortItem) => (data[`order[${val.key}]`] = val.order))
        }
        try {
            const result = await betriebsbeauftragterService.getAllBetriebsbeauftragte(data)
            const items = result['hydra:member']
            const total = result['hydra:totalItems']

            serverItems.value = items
            totalItems.value = total ?? 0
            loading.value = false
        } catch (e: unknown) {
            loading.value = false
        }
    }

    onBeforeMount(async () => {
        await Promise.all([fachprueferStore.fetchFachpruefende()])
    })

    watch([vorname, nachname, firma], () => {
        search.value = String(Date.now())
    })

    const getLandesstellenFilter = () => {
        if (landesstelleFilter.value.all) {
            return { landesstelleId: null }
        } else {
            return { landesstelleId: landesstelleFilter.value.selection }
        }
    }
</script>

<template>
    <v-row class="pb-4">
        <v-col cols="12" md="3">
            <LandesstelleFilter
                @selectionChanged="
                    async (newFilter) => {
                        landesstelleFilter = newFilter
                        await loadItems({ ...tableOptions })
                    }
                "
            />
        </v-col>
        <v-col cols="12" md="3">
            <v-text-field
                v-model="vorname"
                density="compact"
                placeholder="Nach Vorname suchen..."
                append-inner-icon="mdi-magnify"
                hide-details
                clearable
            />
        </v-col>
        <v-col cols="12" md="3">
            <v-text-field
                v-model="nachname"
                density="compact"
                placeholder="Nach Nachname suchen..."
                append-inner-icon="mdi-magnify"
                hide-details
                clearable
            />
        </v-col>
        <v-col cols="12" md="3">
            <v-text-field
                v-model="firma"
                density="compact"
                placeholder="Nach Firma suchen..."
                append-inner-icon="mdi-magnify"
                hide-details
                clearable
            />
        </v-col>
    </v-row>
    <v-data-table-server
        v-model:items-per-page="itemsPerPage"
        :headers="headers"
        :items="serverItems"
        :items-length="totalItems"
        :items-per-page-options="itemsPerPageOptions"
        :items-per-page-text="itemsPerPageText"
        :loading="loading"
        :search="search"
        item-value="name"
        :no-data-text="noItemsText"
        :loading-text="loadingText"
        multi-sort
        hover
        density="compact"
        @update:options="loadItems"
    >
        <template v-slot:item.alteDaten="{ item }">
            <span class="preformatted-text">{{ item.alteDaten }}</span>
        </template>
        <template v-slot:item.firmenAdresse="{ item }">
            <AdresseCell :adresse="item.firmenAdresse" />
        </template>
        <template v-slot:item.qualifikation="{ item }">
            <QualifikationCell v-if="item.qualifikation" :qualifikation="item.qualifikation" />
        </template>
        <template v-slot:item.landesstelleId="{ item }">
            {{ landesstellenStore.getNameById(item.landesstelleId) }}
        </template>
        <template v-slot:item.fachprueferId="{ item }">
            {{ fachprueferStore.getFullNameById(item.fachprueferId) }}
        </template>
        <template v-slot:item.fachbetriebId="{ item }">
            {{ item.fachbetriebId }}
        </template>
        <template v-slot:item.gender="{ item }">
            <GenderCell :gender="item.gender" />
        </template>
        <template v-slot:item.schulungen.schulungen="{ item }">
            <div class="d-flex flex-wrap ga-1 flex-column my-4">
                <v-row v-for="(schulung, key) in item.schulungen.schulungen" :key="key">
                    <SchulungCell :schulung="schulung" :reference-date="null" />
                </v-row>
            </div>
        </template>
        <template v-slot:item.firma="{ item }">
            <template v-if="'' === item.firma?.name"> - </template>
            <template v-else>
                {{ item.firma?.name }}
                <template v-if="item.firma?.rechtsform?.rechtsform">
                    ({{ item.firma?.rechtsform.rechtsform }})
                </template>
            </template>
        </template>
        <template v-slot:item.kontakt="{ item }">
            <template v-for="(telefonnummer, key) in item?.kontakt?.telefon" :key="key">
                <div class="d-flex ga-1">
                    <TelefonnummerCell :telefonnummer="telefonnummer" />
                </div>
            </template>
            <template v-for="(mobil, key) in item?.kontakt?.mobil" :key="key">
                <div class="d-flex ga-1">
                    <TelefonnummerCell :telefonnummer="mobil" />
                </div>
            </template>
        </template>
        <template v-slot:item.kontakt.email="{ item }">
            <template v-for="(email, key) in item?.kontakt?.email" :key="key">
                <div class="d-flex ga-1">
                    <EmailCell :email="email" />
                </div>
            </template>
        </template>
    </v-data-table-server>
</template>

<style scoped>
    :deep(.v-select .v-field-label) {
        color: grey;
        opacity: 1;
    }

    :deep(.v-input input::placeholder) {
        color: grey;
        opacity: 1;
    }
</style>
