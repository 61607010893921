<script setup lang="ts">
    import dayjs from 'dayjs'
    import {
        type Betriebspruefung,
        type Zertifizierung,
        type MarkiereRechnungAlsVersendetRequest
    } from '@/js/model'
    import { ref, computed, onMounted } from 'vue'
    import { useAuthStore, useZertifizierungStore } from '@/stores'
    import { useBetriebspruefungService, useZertifizierungService } from '@/services'

    const props = defineProps<{
        betriebspruefung: Betriebspruefung
    }>()

    const emit = defineEmits<{
        itemChanged: [event: Betriebspruefung]
    }>()

    const authStore = useAuthStore()
    const zertifizierungStore = useZertifizierungStore()
    const versendetAm = ref<Date | null>(null)
    const isUewg = computed(() => {
        return authStore.hasRole('Uewg')
    })

    const service = useBetriebspruefungService()
    const zertifizierungService = useZertifizierungService()

    const dialog = ref<boolean>(false)
    const zertifizierung = ref<Zertifizierung | null>(null)
    const isSubmitDisabled = computed(() => {
        return (
            props.betriebspruefung.pruefungsergebnis.mangel.isUnknown ||
            props.betriebspruefung?.dokumentIds?.length === 0
        )
    })

    onMounted(async () => {
        versendetAm.value = new Date()
        zertifizierung.value = await zertifizierungService.getZertifizierungById(
            props.betriebspruefung.zertifizierungId
        )
    })

    const handleSubmit = async () => {
        const request: MarkiereRechnungAlsVersendetRequest = {
            zertifizierung: `api/zertifizierungen/${props.betriebspruefung.zertifizierungId}`,
            rechnungVersendetAm: dayjs(versendetAm.value).toISOString(),
            betriebspruefung: props.betriebspruefung['@id']
        }

        const result = await zertifizierungService.markiereRechnungAlsVersendet(request)

        if (result) {
            zertifizierung.value = result
            const newBetriebspruefung = await service.getBetriebspruefungById(
                result.betriebspruefungId
            )

            emit('itemChanged', newBetriebspruefung)
            zertifizierungStore.addOrReplaceZertifizierung(result)
            dialog.value = false
        }
    }

    const handleAbort = async () => {
        dialog.value = false
    }
</script>

<template>
    <v-dialog
        v-if="
            betriebspruefung.isAbgeschlossen &&
            isUewg &&
            zertifizierung &&
            !zertifizierung.isRechnungAlsVersendetMarkiert
        "
        v-model="dialog"
        min-width="700"
        max-width="1200"
    >
        <template v-slot:activator="{ props: activatorProps }">
            <v-btn
                v-bind="activatorProps"
                append-icon="mdi-receipt-send"
                variant="elevated"
                color="primary"
                size="small"
                class="my-1"
                data-test="btn-rechnung-als-gesendet-markieren"
                >Rechnung als versendet markieren</v-btn
            >
        </template>
        <v-card prepend-icon="mdi-receipt-send" title="Rechnung als versendet markieren">
            <v-card-subtitle>Versendet am:</v-card-subtitle>
            <v-card-text>
                <VueDatePicker
                    v-if="versendetAm"
                    v-model="versendetAm"
                    :week-numbers="{ type: 'iso' }"
                    placeholder="Datum des Versands"
                    :clearable="false"
                    required
                    :inline="{ input: true }"
                    text-input
                    auto-apply
                    locale="de"
                    :enable-time-picker="false"
                    format="dd.LL.y"
                    cancel-text="abbrechen"
                    select-text="auswählen"
                    :teleport="true"
                    six-weeks="center"
                    :action-row="{ showPreview: false }"
                />
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
                <v-btn text="Abbrechen" variant="plain" @click="handleAbort"></v-btn>

                <v-spacer></v-spacer>

                <v-btn
                    color="primary"
                    text="Rechnung als versendet markieren"
                    variant="tonal"
                    :disabled="isSubmitDisabled"
                    @click="handleSubmit"
                ></v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<style scoped></style>
