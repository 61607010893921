<script setup lang="ts">
    import {
        type Betriebsbeauftragter,
        type Betriebspruefung,
        type ChangeKontaktOfBetriebsbeauftragterRequest
    } from '@/js/model'
    import { useBetriebsbeauftragterService } from '@/services'
    import { computed, onMounted, ref, watch } from 'vue'
    import { useAuthStore, useBetriebsbeauftragterStore } from '@/stores'
    import type { Kontakt } from '@/js/models'
    import { HasBestandeneSchulungCell, SchulungCell } from '@/components'

    const authStore = useAuthStore()

    const props = defineProps<{
        betriebsbeauftragterId: Betriebsbeauftragter['betriebsbeauftragterId']
        betriebspruefung: Betriebspruefung
    }>()

    const emit = defineEmits<{
        betriebsbeauftragterChanged: [event: string]
    }>()

    const betriebsbeauftragterStore = useBetriebsbeauftragterStore()
    const betriebsbeauftragterService = useBetriebsbeauftragterService()

    const dialog = ref<boolean>(false)
    const kontakt = ref<Kontakt>({
        telefon: [],
        mobil: [],
        telefax: [],
        email: [],
        internet: '',
        sonstiges: []
    })

    const isAllowedToAccessKontakt = computed(() => {
        return betriebsbeauftragter.value && authStore.hasRole('Fachpruefer')
    })
    const isSubmitDisabled = computed(() => {
        if (!betriebsbeauftragter.value || !authStore.hasRole('Fachpruefer')) {
            return true
        }
        return (
            kontakt.value.email[0] === betriebsbeauftragter.value.kontakt.email[0] &&
            kontakt.value.mobil[0] === betriebsbeauftragter.value.kontakt.mobil[0] &&
            kontakt.value.telefon[0] === betriebsbeauftragter.value.kontakt.telefon[0] &&
            kontakt.value.sonstiges[0] === betriebsbeauftragter.value.kontakt.sonstiges[0]
        )
    })
    const betriebsbeauftragter = computed(() => {
        return betriebsbeauftragterStore.getBetriebsbeauftragterById(props.betriebsbeauftragterId)
    })

    function setInitialValues() {
        if (betriebsbeauftragter.value && betriebsbeauftragter.value.kontakt) {
            // @chris: warum geht structuredClone() nicht?
            // kontakt.value = structuredClone(betriebsbeauftragter.value.kontakt)
            // oder sollten wir lodash verwenden?
            // import _ from 'lodash';
            //            kontakt.value = _.cloneDeep(betriebsbeauftragter.value.kontakt);
            // oder reicht Folgendes vollkommen aus und ist gängige best practice?
            kontakt.value = JSON.parse(JSON.stringify(betriebsbeauftragter.value.kontakt))
        }
    }

    onMounted(async () => {
        await betriebsbeauftragterStore.fetchBetriebsbeauftragterById(props.betriebsbeauftragterId)
        setInitialValues()
    })
    watch([dialog], async () => {
        // ensure dialog is updated when other kontakt is changed in another dialog with same kontakt
        await betriebsbeauftragterStore.fetchBetriebsbeauftragterById(props.betriebsbeauftragterId)
        setInitialValues()
    })

    const onEmailInput = () => {
        if ('' === kontakt.value.email[0]) {
            kontakt.value.email = []
        }
    }

    const handleChangeKontaktSubmit = async () => {
        const request: ChangeKontaktOfBetriebsbeauftragterRequest = {
            betriebsbeauftragter: `/api/betriebsbeauftragte/${props.betriebsbeauftragterId}`,
            kontakt: kontakt.value
        }

        // allow to remove contact details
        if ('' === request.kontakt?.email[0]) {
            request.kontakt.email = []
        }
        if ('' === request.kontakt?.mobil[0]) {
            request.kontakt.mobil = []
        }
        if ('' === request.kontakt?.telefon[0]) {
            request.kontakt.telefon = []
        }
        if ('' === request.kontakt?.sonstiges[0]) {
            request.kontakt.sonstiges = []
        }

        const result =
            await betriebsbeauftragterService.changeKontaktOfBetriebsbeauftragter(request)

        if (result) {
            emit(
                'betriebsbeauftragterChanged',
                `/api/betriebsbeauftragte/${props.betriebsbeauftragterId}`
            )
            dialog.value = false
        }
    }

    const handleAbort = async () => {
        dialog.value = false
    }
</script>

<template>
    <v-dialog v-if="betriebsbeauftragter" v-model="dialog" max-width="600">
        <template v-slot:activator="{ props: activatorProps }">
            <v-btn
                v-bind="activatorProps"
                :append-icon="isAllowedToAccessKontakt ? 'mdi-pencil' : 'mdi-eye'"
                variant="text"
                class="text-none"
                data-test="action-betriebsbeauftragter-edit"
            >
                {{ betriebsbeauftragter.fullName }}
            </v-btn>
            <has-bestandene-schulung-cell
                :schulungen="betriebsbeauftragter.schulungen"
                :betriebspruefung="betriebspruefung"
            />
        </template>
        <v-card prepend-icon="mdi-calendar" title="Kontaktdaten des Betriebsbeauftragten ändern">
            <v-card-title>{{ betriebsbeauftragter.fullName }}</v-card-title>
            <v-card-text v-if="isAllowedToAccessKontakt">
                <v-row dense>
                    <v-col cols="12" md="12" sm="12"
                        ><v-text-field
                            v-model="kontakt.email[0]"
                            type="email"
                            placeholder="vorname.nachname@example.de"
                            label="E-Mail"
                            name="email"
                            @input="onEmailInput"
                        /><v-text-field
                            v-model="kontakt.mobil[0]"
                            type="tel"
                            placeholder="0175 123 345 6789"
                            label="Mobil"
                            name="mobile"
                        />
                        <v-text-field
                            v-model="kontakt.telefon[0]"
                            type="tel"
                            placeholder="0123 45 67 890"
                            label="Festnetz"
                            name="phone"
                        />
                        <v-text-field
                            v-model="kontakt.sonstiges[0]"
                            placeholder="z. B. Messenger-ID"
                            label="Sonstiges"
                            name="other"
                        />
                    </v-col>
                </v-row>
                <v-alert type="info" color="grey" icon="mdi-eye">
                    Die Kontaktdaten des Betriebsbeauftragten sind nur für Sie als Fachprüfer
                    einsehbar.
                </v-alert>
            </v-card-text>
            <v-divider v-if="isAllowedToAccessKontakt"></v-divider>
            <v-card-title>Schulungen</v-card-title>
            <v-card-text>
                <v-sheet class="d-flex flex-wrap ga-1 flex-column">
                    <v-row
                        v-for="schulung in betriebsbeauftragter.schulungen.schulungen"
                        :key="schulung.schulungAt"
                    >
                        <schulung-cell
                            :schulung="schulung"
                            :reference-date="betriebspruefung.terminAm"
                        />
                    </v-row>
                </v-sheet>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn
                    v-if="isAllowedToAccessKontakt"
                    text="Abbrechen"
                    variant="plain"
                    @click="handleAbort"
                ></v-btn>

                <v-btn
                    v-if="isAllowedToAccessKontakt"
                    color="primary"
                    text="Speichern"
                    variant="tonal"
                    :disabled="isSubmitDisabled"
                    @click="handleChangeKontaktSubmit"
                    data-test="action-betriebsbeauftragter-save"
                />
                <v-btn
                    v-if="!isAllowedToAccessKontakt"
                    color="primary"
                    text="Schließen"
                    variant="tonal"
                    @click="dialog = false"
                    data-test="action-close"
                />
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<style scoped></style>
