<script setup lang="ts">
    import { type Betriebspruefung, type SchliesseBetriebspruefungAbRequest } from '@/js/model'
    import { useBetriebspruefungService } from '@/services'
    import { formatDate } from '@/utils'
    import { ref, computed } from 'vue'
    import DokumentPreviewCell from '@/components/DokumentPreviewCell.vue'

    const props = defineProps<{
        betriebspruefung: Betriebspruefung
    }>()

    const emit = defineEmits<{
        itemChanged: [event: Betriebspruefung]
    }>()

    const service = useBetriebspruefungService()

    const dialog = ref<boolean>(false)
    const isSubmitDisabled = computed(() => {
        return (
            props.betriebspruefung.pruefungsergebnis.mangel.isUnknown ||
            props.betriebspruefung?.dokumentIds?.length === 0
        )
    })
    const handleAbschliessenSubmit = async () => {
        const request: SchliesseBetriebspruefungAbRequest = {
            betriebspruefung: props.betriebspruefung['@id']
        }

        const result = await service.schliesseBetriebspruefungAb(request)

        if (result) {
            emit('itemChanged', result)
            dialog.value = false
        }
    }

    const handleAbort = async () => {
        dialog.value = false
    }
</script>

<template>
    <v-dialog
        v-if="
            !betriebspruefung.isAbgeschlossen && betriebspruefung.pruefungsergebnis.mangel.isKnown
        "
        v-model="dialog"
        min-width="700"
        max-width="1200"
    >
        <template v-slot:activator="{ props: activatorProps }">
            <v-btn
                v-bind="activatorProps"
                append-icon="mdi-receipt-send"
                variant="elevated"
                color="primary"
                size="small"
                class="my-1"
                data-test="btn-betriebspruefung-abschließen"
                >Abschließen vorbereiten</v-btn
            >
        </template>
        <v-card
            prepend-icon="mdi-receipt-send"
            title="Betriebsprüfung abschließen und an Landesstelle senden"
        >
            <v-card-text>
                <v-row dense>
                    <v-col cols="12" md="12" sm="12">
                        <span><b>Prüfungsdatum:</b></span>
                        {{ formatDate(betriebspruefung.terminAm) }}
                    </v-col>
                    <v-divider />
                    <v-col cols="12" md="12" sm="12">
                        <span><b>Prüfungsergebnis:</b></span>
                        {{ betriebspruefung.pruefungsergebnis.mangel.mangel }}
                    </v-col>
                    <v-divider />
                    <v-col cols="12" md="12" sm="12"> </v-col>
                    <v-col cols="12" md="12" sm="12">
                        <div><b>Bemerkung:</b></div>
                        <span
                            v-html="betriebspruefung.pruefungsergebnis.bemerkung ?? '-'"
                            class="nl2br"
                        />
                    </v-col>
                </v-row>

                <v-row v-if="betriebspruefung.dokumentIds.length" dense class="h-50">
                    <v-col>
                        <div><b>Dokumente:</b></div>
                        <v-sheet class="overflow-x-auto" max-height="35vh" border rounded>
                            <v-col
                                cols="auto"
                                v-for="dokumentId in betriebspruefung.dokumentIds"
                                :key="dokumentId"
                            >
                                <DokumentPreviewCell
                                    :dokument-id="dokumentId"
                                    :key="dokumentId"
                                    class="grid"
                                />
                            </v-col>
                        </v-sheet>
                    </v-col>
                </v-row>
                <v-row v-else dense>
                    <v-col cols="12" md="12" sm="12">
                        <v-alert type="error" variant="tonal" icon="mdi-alert">
                            Hinweis: Um den Prüfbericht an die Landesstelle weitergeben zu können,
                            muss der Prüfbericht zum Prüfergebnis hochgeladen werden.
                        </v-alert>
                    </v-col>
                </v-row>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
                <v-btn text="Abbrechen" variant="plain" @click="handleAbort"></v-btn>

                <v-spacer></v-spacer>

                <v-btn
                    color="primary"
                    text="Betriebsprüfung abschließen und an Landesstelle senden"
                    variant="tonal"
                    :disabled="isSubmitDisabled"
                    @click="handleAbschliessenSubmit"
                ></v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<style scoped></style>
